// ** Third Party Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DevConsole from '../../dev/DevConsole'

const MySwal = withReactContent(Swal)

export const alertSuccess = (prop) => {
    return MySwal.fire({
        title: 'Success',
        text: prop.text,
        icon: 'success',
        timerProgressBar: true,
        timer: prop.timer ? prop.timer : undefined,
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
}

export const alertError = (prop) => {
    return MySwal.fire({
        title: 'Error',
        text: prop.text,
        icon: 'error',
        timerProgressBar: true,
        timer: prop.timer ? prop.timer : undefined,
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
}

export const alertWarning  = (prop) => {
    return MySwal.fire({
        title: 'Warning',
        text: prop.text,
        icon: 'warning',
        timerProgressBar: true,
        timer: prop.timer ? prop.timer : undefined,
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
    })
}

export const alertConfirm = (prop) => {
    return MySwal.fire({
        title: prop.title,
        text: prop.text,
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: prop.confirmButtonText || 'ตกลง',
        cancelButtonText: prop.cancelButtonText || 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger right-gap'
        },
        buttonsStyling: false,
        reverseButtons: true
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            prop.onConfirm()
        } else if (result.isDismissed) {
            prop.onClose()
            //   Swal.fire('Changes are not saved', '', 'info')
        }
    })
}