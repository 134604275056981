// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_AF_SERVICE_BASE_URL}/authentication/login`,
  registerEndpoint: '/api/register',
  refreshEndpoint: `${process.env.REACT_APP_AF_SERVICE_BASE_URL}/authentication/refresh-token`,
  logoutEndpoint: '/api/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
