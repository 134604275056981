import JwtService from './jwtService'

function useJwt() {
  const jwt = new JwtService()

  return {
    jwt
  }
}

const { jwt } = useJwt()

export default jwt