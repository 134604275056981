// You can customize the template with the help of this file

//Check logo source from .env
const finnLogoPath = require("@src/assets/images/logo/finn-logo.svg").default
const crLogoPath = require("@src/assets/images/logo/chatruay-logo.png").default
let logoSource = finnLogoPath
try {
  if (process.env.REACT_APP_LOGO_SOURCE === "CR") {
    logoSource = crLogoPath
  }
} catch (error) { }

//Template config options
const themeConfig = {
  app: {
    appName: 'AGAF BackOffice',
    appLogoImage: logoSource
  },
  layout: {
    isRTL: false,
    skin: 'semi-dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
